
export default {
  data: () => {
    return {
      postType: null
    };
  },
  async fetch() {
    const postType = this.$route.params.l1;
    this.postType = postType;
  }
};
